import { render, staticRenderFns } from "./Type.vue?vue&type=template&id=5738ada1&scoped=true&slot=header"
import script from "./Type.vue?vue&type=script&lang=js"
export * from "./Type.vue?vue&type=script&lang=js"
import style0 from "./Type.vue?vue&type=style&index=0&id=5738ada1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5738ada1",
  null
  
)

export default component.exports