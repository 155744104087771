<template slot="header">
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">Room Type Management</h2>
    <button class="btn btn-primary" @click="showModal('add')">
      Add Room Type
    </button>
    <div>
      <table class="table mt-3">
        <thead>
          <tr>
            <!-- <th>#</th> -->
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="type in types" :key="type.id">
            <!-- <td>{{ type.id }}</td> -->
            <td>{{ type.name }}</td>
            <td>
              <button class="btn btn-info btn-sm" @click="showModal('edit', type)">
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add/Edit Modal -->
    <b-modal ref="my-modal" title="Room Type">
      <div>
        <label for="name">Name:</label>
        <b-form-input v-model="name" id="name" required></b-form-input>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveType">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import config from "../../../config";
import Swal from "sweetalert2";
export default {
  'name': 'Buildings',
  data() {
    return {
      types: [],
      editId: null,
      name: "",
      modalType: null
    }
  },
  created() {
    this.getTypes()
  },
  methods: {
    async getTypes() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        const response = await axios.get(config.baseURL + "room/type", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        if (response.status === 200) {
          this.types = response.data.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async storeType() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        const response = await axios.post(config.baseURL + "room/type/store", { name: this.name }, {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        if (response.status === 201) {
          this.getTypes();
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async updateType() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        const response = await axios.put(config.baseURL + "room/type/update", { id: this.editId, name: this.name }, {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        if (response.status === 202) {
          this.getTypes();
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async saveType() {
      if (this.modalType === 'edit') {
        await this.updateType();
      } else {
        await this.storeType()
      }
      this.closeModal();
    },
    showModal(type, item = null) {
      this.modalType = type;
      if (type === 'edit') {
        this.editId = item.id;
        this.name = item.name;
      } else {
        this.editId = null;
        this.name = "";
      }
      this.$refs["my-modal"].show();
    },
    closeModal() {
      this.modalType = null;
      this.editId = null;
      this.$refs["my-modal"].hide();
    }
  }

}
</script>
<style scoped>
/* Add custom CSS to change the background color of table rows */
.table>tbody>tr>td {
  background-color: #1e1e28;
  /* Change this to your desired background color */
}

.table>thead>tr>th {
  background-color: #38384b;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.custom-select {
  color: black !important;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.pagination-button .span {
  align-self: center;
}
</style>
